import React, { useState } from "react";
import Script from "react-load-script";
import { Box, Container, AppBar, Toolbar, Typography } from "@material-ui/core";
import DataTool from "./DataTool";
import IntroText from "./IntroText";

export default function App() {
  // show tool when clicked on intro
  const [tool, showTool] = useState(false);
  const [loaded, isLoaded] = useState(false);
  const [error, isError] = useState(false);

  function loadCharts() {
    window.google.charts.load("current", {
      packages: ["corechart", "controls"],
    });
    window.google.charts.setOnLoadCallback(function () {
      isLoaded(true);
    });
  }

  return (
    <Container maxWidth="lg" disableGutters={true}>
      {/*       <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Race Equity and Inclusion Tool</Typography>
        </Toolbar>
      </AppBar> */}
      <Box mx={1}>
        {!tool && <IntroText showTool={showTool} />}
        {tool && loaded && !error && <DataTool />}
        {tool && error && (
          <Typography variant="h4">
            Something went wrong, please reload the page
          </Typography>
        )}
      </Box>
      <Script
        url="https://www.gstatic.com/charts/loader.js"
        onError={() => isError(true)}
        onLoad={loadCharts}
      />
    </Container>
  );
}
