import React from "react";

import { Typography, Box, Button } from "@material-ui/core";
import BarChartIcon from "@material-ui/icons/BarChart";

export default function IntroText(props) {
  return (
    <Box display="flex" justifyContent="center">
      <Box maxWidth={900} mt={2} px={2}>
        <Box mb={2}>
          <Typography variant="h4">Introduction</Typography>
          <Typography variant="body1" align="justify">
            The Race Equity and Inclusion (REI) tool was developed to help data
            users understand the context behind the persistent disparities and
            inequities between people of color and white people. When
            disaggregating data by race and ethnicity, persistent disparities
            become evident quickly. These disparities have roots in both Georgia
            and federal history and do not exist in a vacuum. In order to ensure
            that all children in Georgia are able to thrive, we must close the
            gaps in outcomes between children of color and white children. To do
            this, we must understand both the data, and the root causes behind
            the disparities shown in the data. Without addressing root causes,
            we won’t be able to improve conditions for all children.
          </Typography>
        </Box>
        <Typography variant="h5">Context</Typography>
        <Typography variant="body1" align="justify">
          Data are paired with messaging that helps identify root causes of
          disparities. There will be counties where the context message doesn’t
          always match perfectly with the data displayed. Context messages are
          rotated so you will see different messages when you display
          indicators. We encourage you to explore the context message links and
          learn more about root causes of disparities.
        </Typography>
        <Box mb={3} mt={2}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => props.showTool(true)}
            startIcon={<BarChartIcon />}
          >
            Show Tool
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
