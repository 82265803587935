export const indicatorNames = {
  milestones_3rdela_develop_rate:
    "3rd grade students achieving Developing Learner or above on Milestones ELA assessment",
  milestones_3rdela_profic_rate:
    "3rd grade students achieving Proficient Learner or above on Milestones ELA assessment",
  milestones_5thela_develop_rate:
    "5th grade students achieving Developing Learner or above on Milestones ELA assessment",
  milestones_5thmath_develop_rate:
    "5th grade students achieving Developing Learner or above on Milestones Math assessment",
  milestones_5thela_profic_rate:
    "5th grade students achieving Proficient Learner or above on Milestones ELA assessment",
  milestones_5thmath_profic_rate:
    "5th grade students achieving Proficient Learner or above on Milestones Math assessment",
  milestones_8thela_develop_rate:
    "8th grade students achieving Developing Learner or above on Milestones ELA assessment",
  milestones_8thmath_develop_rate:
    "8th grade students achieving Developing Learner or above on Milestones Math assessment",
  milestones_8thela_profic_rate:
    "8th grade students achieving Proficient Learner or above on Milestones ELA assessment",
  milestones_8thmath_profic_rate:
    "8th grade students achieving Proficient Learner or above on Milestones Math assessment",
  gshs_9th_use_rate:
    "9th grade students reporting alcohol use in the past 30 days",
  gshs_9th_risk_rate:
    "9th grade students reporting perception of negative risk with alcohol consumption",
  educ_attain_25_bach_rate:
    "Adult educational attainment: Bachelor’s degree or higher",
  educ_attain_25_hs_rate:
    "Adult educational attainment: High−school graduate or higher",
  bths_to_lt_hs_rate:
    "Babies born to mothers with less than 12 years of education",
  q_rated_rate: "Centers and family child care homes rated in Quality Rated",
  child_dths_rate: "Child deaths, ages 1−14",
  school_absence_gt_15_rate: "Children absent more than 15 days from school",
  reunifications_rate:
    "Children leaving foster care who are reunified with their families or placed with a relative within 12 months of entering foster care",
  child_poverty_rate: "Children living in poverty",
  child_single_parent_fam_rate: "Children living in single−parent families",
  not_attend_preschool_rate: "Children not attending preschool, ages 3 to 4",
  no_parent_in_labr_force_rate: "Children whose parents lack secure employment",
  child_abuse_rate: "Children with a substantiated incident of abuse",
  child_abuse_neglect_rate:
    "Children with a substantiated incident of abuse and/or neglect",
  child_neglect_rate: "Children with a substantiated incident of neglect",
  no_health_insurance_rate: "Children without health insurance",
  crime_part2_rate: "Crime rate, other crimes",
  crime_part1_rate: "Crime rate, violent crimes, age 17 or older",
  families_lt_150per_of_pov_rate:
    "Families, with children, with annual incomes less than 150% of the federal poverty threshold",
  hs_age20_rate:
    "First birth to mother age 20 or older with 12 years of education",
  owner_occupied_housing_rate: "Homeownership",
  infant_dths_rate: "Infant mortality",
  lbw_rate: "Low−birthweight babies",
  std_rate: "STD incidence for youth, ages 15−19",
  hs_grad_cohort_rate: "Students who graduate from high school on time",
  bth_15_19_rate: "Teen births, ages 15−19",
  teen_violent_dths_rate:
    "Teen deaths by homicide, suicide and accident, ages 15−19",
  teen_deaths_rate: "Teen deaths, ages 15−19",
  repeat_bths_to_teens_rate:
    "Teen mothers giving birth to another child before age 20, ages 15−19",
  preg_15_17_rate: "Teen pregnancies, ages 15−17",
  not_school_not_work_rate: "Teens not in school and not working, ages 16−19",
  hs_dropouts_rate: "Teens who are high−school dropouts, ages 16−19",
  unemployment_rate: "Unemployment",
};
export const indicatorTypes = {
  bth_15_19_rate: "Per 1,000",
  bths_to_lt_hs_rate: "Percent",
  child_abuse_neglect_rate: "Per 1,000",
  child_abuse_rate: "Per 1,000",
  child_dths_rate: "Per 100,000",
  child_neglect_rate: "Per 1,000",
  child_poverty_rate: "Percent",
  child_single_parent_fam_rate: "Percent",
  crime_part1_rate: "Per 1,000",
  crime_part2_rate: "Per 1,000",
  educ_attain_25_bach_rate: "Percent",
  educ_attain_25_hs_rate: "Percent",
  families_lt_150per_of_pov_rate: "Percent",
  gshs_9th_risk_rate: "Percent",
  gshs_9th_use_rate: "Percent",
  hs_age20_rate: "Percent",
  hs_dropouts_rate: "Percent",
  hs_grad_cohort_rate: "Percent",
  infant_dths_rate: "Per 1,000",
  lbw_rate: "Percent",
  milestones_3rdela_develop_rate: "Percent",
  milestones_3rdela_profic_rate: "Percent",
  milestones_5thela_develop_rate: "Percent",
  milestones_5thela_profic_rate: "Percent",
  milestones_5thmath_develop_rate: "Percent",
  milestones_5thmath_profic_rate: "Percent",
  milestones_8thela_develop_rate: "Percent",
  milestones_8thela_profic_rate: "Percent",
  milestones_8thmath_develop_rate: "Percent",
  milestones_8thmath_profic_rate: "Percent",
  no_health_insurance_rate: "Percent",
  no_parent_in_labr_force_rate: "Percent",
  not_attend_preschool_rate: "Percent",
  not_school_not_work_rate: "Percent",
  owner_occupied_housing_rate: "Percent",
  preg_15_17_rate: "Per 1,000",
  q_rated_rate: "Percent",
  repeat_bths_to_teens_rate: "Percent",
  reunifications_rate: "Percent",
  school_absence_gt_15_rate: "Percent",
  std_rate: "Per 1,000",
  teen_deaths_rate: "Per 100,000",
  teen_violent_dths_rate: "Per 100,000",
  unemployment_rate: "Percent",
};
export const indicatorCategs = {
  bth_15_19_rate: "healthy-children",
  bths_to_lt_hs_rate: "healthy-children",
  child_abuse_neglect_rate: "stable-families",
  child_abuse_rate: "stable-families",
  child_dths_rate: "healthy-children",
  child_neglect_rate: "stable-families",
  child_poverty_rate: "thriving-communities",
  child_single_parent_fam_rate: "stable-families",
  crime_part1_rate: "thriving-communities",
  crime_part2_rate: "thriving-communities",
  educ_attain_25_bach_rate: "thriving-communities",
  educ_attain_25_hs_rate: "thriving-communities",
  families_lt_150per_of_pov_rate: "thriving-communities",
  gshs_9th_risk_rate: "healthy-children",
  gshs_9th_use_rate: "healthy-children",
  hs_age20_rate: "stable-families",
  hs_dropouts_rate: "children-succeeding",
  hs_grad_cohort_rate: "children-succeeding",
  infant_dths_rate: "healthy-children",
  lbw_rate: "healthy-children",
  milestones_3rdela_develop_rate: "children-succeeding",
  milestones_3rdela_profic_rate: "children-succeeding",
  milestones_5thela_develop_rate: "children-succeeding",
  milestones_5thela_profic_rate: "children-succeeding",
  milestones_5thmath_develop_rate: "children-succeeding",
  milestones_5thmath_profic_rate: "children-succeeding",
  milestones_8thela_develop_rate: "children-succeeding",
  milestones_8thela_profic_rate: "children-succeeding",
  milestones_8thmath_develop_rate: "children-succeeding",
  milestones_8thmath_profic_rate: "children-succeeding",
  no_health_insurance_rate: "healthy-children",
  no_parent_in_labr_force_rate: "stable-families",
  not_attend_preschool_rate: "healthy-children",
  not_school_not_work_rate: "children-succeeding",
  owner_occupied_housing_rate: "thriving-communities",
  preg_15_17_rate: "healthy-children",
  q_rated_rate: "healthy-children",
  repeat_bths_to_teens_rate: "healthy-children",
  reunifications_rate: "stable-families",
  school_absence_gt_15_rate: "children-succeeding",
  std_rate: "healthy-children",
  teen_deaths_rate: "healthy-children",
  teen_violent_dths_rate: "healthy-children",
  unemployment_rate: "thriving-communities",
};

export const categText = {
  "children-succeeding": [
    {
      text:
        "Black students spend less time in the classroom due to unequal disciplinary actions which hinders their access to a quality education. Black students are nearly two times as likely to be suspended without educational services and 3.8 times as likely to receive one or more out-of-school suspensions as white students.",
      source:
        "https://www.brookings.edu/research/disproportionality-in-student-discipline-connecting-policy-to-research/",
    },
    {
      text:
        "Black Americans have been exposed to generations of legal and illegal measures to deny them basic rights. The interface between racism/classism and related economic and social disadvantages is the key to understanding the achievement gaps between of Black and white children.",
      source:
        "https://www.acslaw.org/expertforum/why-brown-v-board-of-education-still-matters/",
    },
    {
      text:
        "Students of color are often concentrated in schools with fewer resources. Schools with 90% or more students of color spend $733 less per student per year than schools with 90 percent or more white students.",
      source:
        "https://www.thecommonwealthinstitute.org/2017/10/26/unequal-opportunities-fewer-resources-worse-outcomes-for-students-in-schools-with-concentrated-poverty/",
    },
    {
      text:
        "Latino children who have limited English-language skills receive fewer opportunities to learn than students who are bilingual or speak only English. This limits students’ access to post-secondary education, and those who do successfully access it may not be well prepared to perform because of knowledge gaps and lower language skills.",
      source: "https://doi.org/10.1093/cs/cds001",
    },
    {
      text:
        "Research has shown systematic bias in teacher expectations. Student-teacher racial mismatch reduces teacher’s expectations particularly for Black students and affects their long term educational success.",
      source:
        "https://www.educationnext.org/power-of-teacher-expectations-racial-bias-hinders-student-attainment/",
    },
  ],
};

export const colors = [
  "#6C2B4C",
  "#81AC40",
  "#836A34",
  "#293559",
  "#833A34",
  "#286533",
  "#362A5A",
];

export const series = {
  0: {
    lineDashStyle: [2, 2],
  },
  1: {
    lineDashStyle: [4, 4],
  },
  2: {
    lineDashStyle: [5, 1, 3],
  },
  3: {
    lineDashStyle: [4, 1],
  },
  4: {
    lineDashStyle: [10, 2],
  },
  5: {
    lineDashStyle: [14, 2, 7, 2],
  },
  6: {
    lineDashStyle: [14, 2, 2, 7],
  },
  7: {
    lineDashStyle: [2, 2, 20, 2, 20, 2],
  },
};

export const styles = {
  "American Indian or Alaskan Native": {
    lineDashStyle: [5, 1],
    color: "#834E34",
  },
  "American Indian or Alaska Native, Not Hispanic or Latino": {
    lineDashStyle: [5, 1],
    color: "#834E34",
  },
  "American Indian and Alaskan Native": {
    lineDashStyle: [5, 1],
    color: "#834E34",
  },
  "American Indian/Alaskan": { lineDashStyle: [5, 1], color: "#834E34" },
  Asian: { lineDashStyle: [2, 3], color: "#293559" },
  "Asian, Not Hispanic or Latino": { lineDashStyle: [5, 3], color: "#293559" },
  "Asian/Pacific Islander": { lineDashStyle: [5, 3], color: "#293559" },
  Black: { lineDashStyle: [5, 3, 3, 4, 4], color: "#6C2B4C" },
  "Black or African American": {
    lineDashStyle: [5, 3, 3, 4, 4],
    color: "#6C2B4C",
  },
  "Black or African-American": {
    lineDashStyle: [5, 3, 3, 4, 4],
    color: "#6C2B4C",
  },
  "Black or African-American, Not Hispanic or Latino": {
    lineDashStyle: [5, 4, 2, 2],
    color: "#6C2B4C",
  },
  Hispanic: { lineDashStyle: [2, 4, 4, 2, 2], color: "#836A34" },
  "Hispanic or Latino": { lineDashStyle: [2, 4, 4, 2, 2], color: "#836A34" },
  Latino: { lineDashStyle: [2, 4, 4, 2, 2], color: "#836A34" },
  "Multi-Racial": { lineDashStyle: [2, 5, 5, 1], color: "#3F2859" },
  Multiracial: { lineDashStyle: [2, 5, 5, 1], color: "#3F2859" },
  "Multiracial, Not Hispanic or Latino": {
    lineDashStyle: [3, 3, 4, 5],
    color: "#3F2859",
  },
  Native: { lineDashStyle: [3, 3, 3, 5, 2], color: "#834E34" },
  "Native Hawaiian or Other Pacific Islander": {
    lineDashStyle: [4, 1, 4, 1, 5],
    color: "#235845",
  },
  "Native Hawaiian or Other Pacific Islander, Not Hispanic or Latino": {
    lineDashStyle: [5, 5, 2],
    color: "#235845",
  },
  "Native Hawaiian and Other Pacific Islander": {
    lineDashStyle: [4, 1, 4, 1, 5],
    color: "#235845",
  },
  "Some Other Race": { lineDashStyle: [4, 2, 1], color: "#235845" },
  "Two or More Races": { lineDashStyle: [3, 1, 5], color: "#3F2859" },
  White: { lineDashStyle: [2, 3, 5, 1], color: "#5F7A30" },
  "White, Not Hispanic or Latino": {
    lineDashStyle: [2, 3, 2],
    color: "#5F7A30",
  },
  "White, not Hispanic or Latino": {
    lineDashStyle: [2, 3, 2],
    color: "#5F7A30",
  },
};

export const indicatorMessages = {
  bth_15_19_rate: [
    {
      text:
        "Teens of color are less likely to have access to quality health care and contraceptive services and more likely to live in neighborhoods where jobs are scarce. This segregation of neighborhoods and unequal access to quality education, economic opportunities, and healthcare perpetuates disparities in health outcomes.",
      source:
        "https://www.pewtrusts.org/en/research-and-analysis/blogs/stateline/2015/3/03/racial-and-ethnic-disparities-persist-in-teen-pregnancy-rates  ",
    },
    {
      text:
        "Segregation of hospitals due to Jim Crow Laws hindered Black American\u2019s access to proper medical treatment and preventive care. Poor healthcare access, especially in rural areas, persists in healthcare today through ongoing systemic racism and implicit bias manifested in service inequalities.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Black Americans, Asian Americans, and Latinos are more likely than whites to experience difficulty communicating with their physician, to feel that they are treated with disrespect when receiving health care, to experience barriers to access to care, and to feel they would receive better care if they were of a different race or ethnicity.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Latino access to health care is impacted by language and cultural barriers, limited access to preventative care, confusion about eligibility for services, and lack of health insurance. Latinos have the highest uninsured rate of any racial or ethnic group in the U.S.",
      source: "https://minorityhealth.hhs.gov/omh/browse.aspx?lvl=3&lvlid=64",
    },
  ],
  bths_to_lt_hs_rate: [
    {
      text:
        "Teens of color are less likely to have access to quality health care and contraceptive services and more likely to live in neighborhoods where jobs are scarce. This segregation of neighborhoods and unequal access to quality education, economic opportunities, and healthcare perpetuates disparities in health outcomes.",
      source:
        "https://www.pewtrusts.org/en/research-and-analysis/blogs/stateline/2015/3/03/racial-and-ethnic-disparities-persist-in-teen-pregnancy-rates  ",
    },
    {
      text:
        "Black Americans, Asian Americans, and Latinos are more likely than whites to experience difficulty communicating with their physician, to feel that they are treated with disrespect when receiving health care, to experience barriers to access to care, and to feel they would receive better care if they were of a different race or ethnicity.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Latino children who have limited English-language skills receive fewer opportunities to learn than students who are bilingual or speak only English. This limits students\u2019 access to post-secondary education, and those who do successfully access it may not be well prepared to perform because of knowledge gaps and lower language skills.",
      source: "https://doi.org/10.1093/cs/cds001",
    },
    {
      text:
        "Research has shown systematic bias in teacher expectations. Student-teacher racial mismatch reduces teacher\u2019s expectations particularly for Black students and affects their long term educational success.",
      source: "https://doi.org/10.1016/j.econedurev.2016.03.002.",
    },
  ],
  child_dths_rate: [
    {
      text:
        "Racial bias resulting in injustice and neglect in healthcare leads to elevated maternal stress before and during pregnancy. This stress affects fetal growth and birth outcomes and has been linked to birth weight independent of other medical and sociodemographic factors.",
      source:
        "https://journals.lww.com/clinicalobgyn/Abstract/2008/06000/Race,_Racism,_and_Racial_Disparities_in_Adverse.19.aspx",
    },
    {
      text:
        "Teens of color are less likely to have access to quality health care and contraceptive services and more likely to live in neighborhoods where jobs are scarce. This segregation of neighborhoods and unequal access to quality education, economic opportunities, and healthcare perpetuates disparities in health outcomes.",
      source:
        "https://www.pewtrusts.org/en/research-and-analysis/blogs/stateline/2015/3/03/racial-and-ethnic-disparities-persist-in-teen-pregnancy-rates  ",
    },
    {
      text:
        "Segregation of hospitals due to Jim Crow Laws hindered Black American\u2019s access to proper medical treatment and preventive care. Poor healthcare access, especially in rural areas, persists in healthcare today through ongoing systemic racism and implicit bias manifested in service inequalities.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Latino children are least likely to have health insurance and a medical home. Long standing barriers include language, cultural differences, and concerns about immigration status. Most Latino children have parents who are working full-time, year-round but in low income jobs that do not provide benefits.",
      source: "https://eric.ed.gov/?id=ED571647",
    },
    {
      text:
        "Black Americans, Asian Americans, and Latinos are more likely than whites to experience difficulty communicating with their physician, to feel that they are treated with disrespect when receiving health care, to experience barriers to access to care, and to feel they would receive better care if they were of a different race or ethnicity.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Latino access to health care is impacted by language and cultural barriers, limited access to preventative care, confusion about eligibility for services, and lack of health insurance. Latinos have the highest uninsured rate of any racial or ethnic group in the U.S.",
      source: "https://minorityhealth.hhs.gov/omh/browse.aspx?lvl=3&lvlid=64",
    },
  ],
  child_poverty_rate: [
    {
      text:
        "Numerous research studies have shown how institutional and structural factors contribute to racial/ethnic poverty gaps. Structural racial bias in hiring practices and a history of school segregation have exacerbated these disparities over time.",
      source: "https://doi.org/10.1073/pnas.1706255114",
    },
    {
      text:
        "The legacies of racism account in large part for the fact that Black and Latino adults continue to have higher rates of unemployment than their white counterparts, also resulting in reduced income and wealth.",
      source:
        "https://www.pewsocialtrends.org/2016/06/27/1-demographic-trends-and-economic-well-being/",
    },
    {
      text:
        "Structural racism in the U.S. housing system, such as in home loans, has contributed for centuries to stark and persistent racial disparities in wealth, especially between Black and white households. If current trends continue it could take more than 200 years for the average Black family to catch up to white families.",
      source:
        "https://www.pewresearch.org/fact-tank/2017/01/10/blacks-and-hispanics-face-extra-challenges-in-getting-home-loans/",
    },
    {
      text:
        "The legacies of racism account in large part for the fact that Black and Latino adults continue to have lower rates of college completion than their white counterparts, resulting in reduced income and wealth.",
      source:
        "https://www.pewsocialtrends.org/2016/06/27/1-demographic-trends-and-economic-well-being/",
    },
    {
      text:
        "Recent immigrants tend to have the lowest income levels and are at the bottom of the economic ladder. Lower earnings, lack of benefits, and few worker protections characterize the jobs that immigrant workers are clustered in. Latino children in first- and second-generation families are more likely to have one or more full-time working parents compared to children in U.S. born families.",
      source: "https://eric.ed.gov/?id=ED571647",
    },
  ],
  educ_attain_25_bach_rate: [
    {
      text:
        "The legacies of racism account in large part for the fact that Black and Latino adults continue to have lower rates of college completion than their white counterparts, resulting in reduced income and wealth.",
      source:
        "https://www.pewsocialtrends.org/2016/06/27/1-demographic-trends-and-economic-well-being/",
    },
    {
      text:
        "The GI bill provided education funding for white veterans to attend college or trade school in order to build their skill set and find gainful employment, but Black veterans were typically denied access to the bill.",
      source:
        "https://www.npr.org/2017/05/03/526655831/a-forgotten-history-of-how-the-u-s-government-segregated-america",
    },
    {
      text:
        "Gaps in college opportunity are influenced by disparities in students\u2019 experiences in education, starting in preschool. Racial and economic isolation in schools and neighborhoods has been documented to impact the racial and ethnic gap in college completion. While high school graduation rates have improved for all race/ethnicities, the gap between Latino and Black college graduates compared to white college graduates has widened.",
      source:
        "http://www2.ed.gov/rschstat/research/pubs/advancing-diversity-inclusion.pdf.",
    },
    {
      text:
        "Students of color, on average, have less access to higher level courses and advanced placement courses in high school as well as less access to counselors to help them navigate college applications.",
      source:
        "http://www2.ed.gov/rschstat/research/pubs/advancing-diversity-inclusion.pdf.",
    },
    {
      text:
        "While Latino high school graduation rates have improved, Latino college completion rates lag behind Black and white students. Academic preparedness, financial issues, and cultural differences are among the barriers, especially for first-generation college students.",
      source: "https://eric.ed.gov/?id=ED571647",
    },
  ],
  educ_attain_25_hs_rate: [
    {
      text:
        "The legacies of racism account in large part for the fact that Black and Latino adults continue to have lower rates of college completion than their white counterparts, resulting in reduced income and wealth.",
      source:
        "https://www.pewsocialtrends.org/2016/06/27/1-demographic-trends-and-economic-well-being/",
    },
    {
      text:
        "The GI bill provided education funding for white veterans to attend college or trade school in order to build their skill set and find gainful employment, but Black veterans were typically denied access to the bill.",
      source:
        "https://www.npr.org/2017/05/03/526655831/a-forgotten-history-of-how-the-u-s-government-segregated-america",
    },
    {
      text:
        "Gaps in college opportunity are influenced by disparities in students\u2019 experiences in education, starting in preschool. Racial and economic isolation in schools and neighborhoods has been documented to impact the racial and ethnic gap in college completion. While high school graduation rates have improved for all race/ethnicities, the gap between Latino and Black college graduates compared to white college graduates has widened.",
      source:
        "http://www2.ed.gov/rschstat/research/pubs/advancing-diversity-inclusion.pdf.",
    },
    {
      text:
        "Students of color, on average, have less access to higher level courses and advanced placement courses in high school as well as less access to counselors to help them navigate college applications.",
      source:
        "http://www2.ed.gov/rschstat/research/pubs/advancing-diversity-inclusion.pdf.",
    },
    {
      text:
        "While Latino high school graduation rates have improved, Latino college completion rates lag behind Black and white students. Academic preparedness, financial issues, and cultural differences are among the barriers, especially for first-generation college students.",
      source: "https://eric.ed.gov/?id=ED571647",
    },
  ],
  hs_age20_rate: [
    {
      text:
        "Teens of color are less likely to have access to quality health care and contraceptive services and more likely to live in neighborhoods where jobs are scarce. This segregation of neighborhoods and unequal access to quality education, economic opportunities, and healthcare perpetuates disparities in health outcomes.",
      source:
        "https://www.pewtrusts.org/en/research-and-analysis/blogs/stateline/2015/3/03/racial-and-ethnic-disparities-persist-in-teen-pregnancy-rates  ",
    },
    {
      text:
        "Gaps in college opportunity are influenced by disparities in students\u2019 experiences in education, starting in preschool. Racial and economic isolation in schools and neighborhoods has been documented to impact the racial and ethnic gap in college completion. While high school graduation rates have improved for all race/ethnicities, the gap between Latino and Black college graduates compared to white college graduates has widened.",
      source:
        "http://www2.ed.gov/rschstat/research/pubs/advancing-diversity-inclusion.pdf.",
    },
    {
      text:
        "Students of color, on average, have less access to higher level courses and advanced placement courses in high school as well as less access to counselors to help them navigate college applications.",
      source:
        "http://www2.ed.gov/rschstat/research/pubs/advancing-diversity-inclusion.pdf.",
    },
  ],
  infant_dths_rate: [
    {
      text:
        "Racial bias resulting in injustice and neglect in healthcare leads to elevated maternal stress before and during pregnancy. This stress affects fetal growth and birth outcomes and has been linked to birth weight independent of other medical and sociodemographic factors.",
      source:
        "https://journals.lww.com/clinicalobgyn/Abstract/2008/06000/Race,_Racism,_and_Racial_Disparities_in_Adverse.19.aspx",
    },
    {
      text:
        "Teens of color are less likely to have access to quality health care and contraceptive services and more likely to live in neighborhoods where jobs are scarce. This segregation of neighborhoods and unequal access to quality education, economic opportunities, and healthcare perpetuates disparities in health outcomes.",
      source:
        "https://www.pewtrusts.org/en/research-and-analysis/blogs/stateline/2015/3/03/racial-and-ethnic-disparities-persist-in-teen-pregnancy-rates  ",
    },
    {
      text:
        "Segregation of hospitals due to Jim Crow Laws hindered Black American\u2019s access to proper medical treatment and preventive care. Poor healthcare access, especially in rural areas, persists in healthcare today through ongoing systemic racism and implicit bias manifested in service inequalities.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Latino children are least likely to have health insurance and a medical home. Long standing barriers include language, cultural differences, and concerns about immigration status. Most Latino children have parents who are working full-time, year-round but in low income jobs that do not provide benefits.",
      source: "https://eric.ed.gov/?id=ED571647",
    },
    {
      text:
        "Black Americans, Asian Americans, and Latinos are more likely than whites to experience difficulty communicating with their physician, to feel that they are treated with disrespect when receiving health care, to experience barriers to access to care, and to feel they would receive better care if they were of a different race or ethnicity.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Latino access to health care is impacted by language and cultural barriers, limited access to preventative care, confusion about eligibility for services, and lack of health insurance. Latinos have the highest uninsured rate of any racial or ethnic group in the U.S.",
      source: "https://minorityhealth.hhs.gov/omh/browse.aspx?lvl=3&lvlid=64",
    },
  ],
  lbw_rate: [
    {
      text:
        "Racial bias resulting in injustice and neglect in healthcare leads to elevated maternal stress before and during pregnancy. This stress affects fetal growth and birth outcomes and has been linked to birth weight independent of other medical and sociodemographic factors.",
      source:
        "https://journals.lww.com/clinicalobgyn/Abstract/2008/06000/Race,_Racism,_and_Racial_Disparities_in_Adverse.19.aspx",
    },
    {
      text:
        "Segregation of hospitals due to Jim Crow Laws hindered Black American\u2019s access to proper medical treatment and preventive care. Poor healthcare access, especially in rural areas, persists in healthcare today through ongoing systemic racism and implicit bias manifested in service inequalities.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Black Americans, Asian Americans, and Latinos are more likely than whites to experience difficulty communicating with their physician, to feel that they are treated with disrespect when receiving health care, to experience barriers to access to care, and to feel they would receive better care if they were of a different race or ethnicity.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Latino access to health care is impacted by language and cultural barriers, limited access to preventative care, confusion about eligibility for services, and lack of health insurance. Latinos have the highest uninsured rate of any racial or ethnic group in the U.S.",
      source: "https://minorityhealth.hhs.gov/omh/browse.aspx?lvl=3&lvlid=64",
    },
  ],
  no_health_insurance_rate: [
    {
      text:
        "Latino children are least likely to have health insurance and a medical home. Long standing barriers include language, cultural differences, and concerns about immigration status. Most Latino children have parents who are working full-time, year-round but in low income jobs that do not provide benefits.",
      source: "https://eric.ed.gov/?id=ED571647",
    },
    {
      text:
        "Black Americans, Asian Americans, and Latinos are more likely than whites to experience difficulty communicating with their physician, to feel that they are treated with disrespect when receiving health care, to experience barriers to access to care, and to feel they would receive better care if they were of a different race or ethnicity.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Latino access to health care is impacted by language and cultural barriers, limited access to preventative care, confusion about eligibility for services, and lack of health insurance. Latinos have the highest uninsured rate of any racial or ethnic group in the U.S.",
      source: "https://minorityhealth.hhs.gov/omh/browse.aspx?lvl=3&lvlid=64",
    },
  ],
  owner_occupied_housing_rate: [
    {
      text:
        "Structural racism in the U.S. housing system, such as in home loans, has contributed for centuries to stark and persistent racial disparities in wealth, especially between Black and white households. If current trends continue it could take more than 200 years for the average Black family to catch up to white families.",
      source:
        "https://www.pewresearch.org/fact-tank/2017/01/10/blacks-and-hispanics-face-extra-challenges-in-getting-home-loans/",
    },
    {
      text:
        "Beginning in the 1930s, lenders drew lines on maps around neighborhoods with high non-white populations and low incomes to identify areas where they would not invest resources, often in the form of home loans and business investments, but also in health care and retail services. Called \u201cRed Lining,\u201d This practice directly impacted the ability of Black Americans to access financial opportunity and build generational wealth",
      source:
        "https://www.citylab.com/equity/2018/04/how-the-fair-housing-act-failed-black-homeowners/557576/",
    },
    {
      text:
        "Black Americans who served in World War II were not able to access the GI Bill of 1944., unlike their white counterparts. The GI Bill provided returning white veterans with low-interest mortgage loans so they could easily buy homes and build wealth.",
      source:
        "https://www.npr.org/2017/05/03/526655831/a-forgotten-history-of-how-the-u-s-government-segregated-america",
    },
    {
      text:
        "Throughout Reconstruction and well into the 20th Century, Southern states implemented local and state laws that prevented Black Americans from voting, from literacy tests to property ownership requirements, despite the federal enactment of the 14th and 15th amendments which expanded such rights to Black Americans. Thus, Black Americans were not able to shape their government or policies to respond to their needs and priorities.",
      source:
        "https://www.npr.org/2017/05/03/526655831/a-forgotten-history-of-how-the-u-s-government-segregated-america",
    },
  ],
  preg_15_17_rate: [
    {
      text:
        "Teens of color are less likely to have access to quality health care and contraceptive services and more likely to live in neighborhoods where jobs are scarce. This segregation of neighborhoods and unequal access to quality education, economic opportunities, and healthcare perpetuates disparities in health outcomes.",
      source:
        "https://www.pewtrusts.org/en/research-and-analysis/blogs/stateline/2015/3/03/racial-and-ethnic-disparities-persist-in-teen-pregnancy-rates  ",
    },
    {
      text:
        "Segregation of hospitals due to Jim Crow Laws hindered Black American\u2019s access to proper medical treatment and preventive care. Poor healthcare access, especially in rural areas, persists in healthcare today through ongoing systemic racism and implicit bias manifested in service inequalities.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Black Americans, Asian Americans, and Latinos are more likely than whites to experience difficulty communicating with their physician, to feel that they are treated with disrespect when receiving health care, to experience barriers to access to care, and to feel they would receive better care if they were of a different race or ethnicity.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Latino access to health care is impacted by language and cultural barriers, limited access to preventative care, confusion about eligibility for services, and lack of health insurance. Latinos have the highest uninsured rate of any racial or ethnic group in the U.S.",
      source: "https://minorityhealth.hhs.gov/omh/browse.aspx?lvl=3&lvlid=64",
    },
  ],
  repeat_bths_to_teens_rate: [
    {
      text:
        "Teens of color are less likely to have access to quality health care and contraceptive services and more likely to live in neighborhoods where jobs are scarce. This segregation of neighborhoods and unequal access to quality education, economic opportunities, and healthcare perpetuates disparities in health outcomes.",
      source:
        "https://www.pewtrusts.org/en/research-and-analysis/blogs/stateline/2015/3/03/racial-and-ethnic-disparities-persist-in-teen-pregnancy-rates  ",
    },
    {
      text:
        "Segregation of hospitals due to Jim Crow Laws hindered Black American\u2019s access to proper medical treatment and preventive care. Poor healthcare access, especially in rural areas, persists in healthcare today through ongoing systemic racism and implicit bias manifested in service inequalities.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Black Americans, Asian Americans, and Latinos are more likely than whites to experience difficulty communicating with their physician, to feel that they are treated with disrespect when receiving health care, to experience barriers to access to care, and to feel they would receive better care if they were of a different race or ethnicity.",
      source: "https://ajph.aphapublications.org/doi/10.2105/AJPH.2009.184622",
    },
    {
      text:
        "Latino access to health care is impacted by language and cultural barriers, limited access to preventative care, confusion about eligibility for services, and lack of health insurance. Latinos have the highest uninsured rate of any racial or ethnic group in the U.S.",
      source: "https://minorityhealth.hhs.gov/omh/browse.aspx?lvl=3&lvlid=64",
    },
  ],
  teen_deaths_rate: [
    {
      text:
        "Black and Latino teens are much more likely to be exposed to violence than white teens.  Systemic racism and poverty has had many adverse impacts on neighborhoods where children of color live.",
      source:
        "https://www.childtrends.org/indicators/teen-homicide-suicide-and-firearm-deaths.",
    },
  ],
  teen_violent_dths_rate: [
    {
      text:
        "Black and Latino teens are much more likely to be exposed to violence than white teens.  Systemic racism and poverty has had many adverse impacts on neighborhoods where children of color live.",
      source:
        "https://www.childtrends.org/indicators/teen-homicide-suicide-and-firearm-deaths.",
    },
  ],
};
