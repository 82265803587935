import React, { useEffect, useRef } from "react";
import { debounce } from "./utils";

export default function LineGraph(props) {
  const chartContainer = useRef(null);
  const chartWidth = "80%";
  // adjust height of mobile graphs
  let divHeight = "400px";
  let graphHeight = "75%";

  if (props.isMobile) {
    divHeight = "300px";
    graphHeight = "70%";
  }
  const chartOptions = {
    vAxis: {
      minorGridlines: {
        count: 0,
        color: "transparent",
      },
      gridlines: {
        count: -1,
      },
    },
    hAxis: {
      format: "####",
      minorGridlines: {
        count: 0,
        color: "transparent",
      },
      gridlines: {
        count: props.chartData["rows"].length,
      },
    },

    title: props.chartCounty + ", " + props.chartIndic,
    legend: {
      position: "top",
      maxLines: 3,
    },
    chartArea: {
      left: "12%",
      width: chartWidth,
      height: graphHeight,
    },
    series: props.chartStyle,
    lineWidth: 1.5,
  };

  useEffect(() => {
    const data = new window.google.visualization.DataTable(props.chartData);
    // if formatting year as number, make sure comma isnt displayed
    const date_formatter = new window.google.visualization.NumberFormat({
      pattern: "####",
    });
    date_formatter.format(data, 0);
    // filter to years since 2009
    const view = new window.google.visualization.DataView(data);
    view.setRows(data.getFilteredRows([{ column: 0, minValue: 2010 }]));
    // format y axis according to data type
    let axisFormat, axisTitle;
    if (props.chartDataType === "Percent") {
      axisFormat = "#'%'";
      axisTitle = null;
    } else {
      axisTitle = props.chartDataType;
      axisFormat = null;
    }
    chartOptions["vAxis"]["title"] = axisTitle;
    chartOptions["vAxis"]["format"] = axisFormat;
    // draw chart
    const chart = new window.google.visualization.LineChart(
      chartContainer.current
    );
    chart.draw(view, chartOptions);

    // https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
    const debouncedHandleResize = debounce(function handleResize() {
      chart.draw(view, chartOptions);
    }, 200);
    window.addEventListener("resize", debouncedHandleResize);
    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return <div style={{ height: divHeight }} ref={chartContainer} />;
}
