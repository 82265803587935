import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Paper,
  Box,
  TextField,
  Grid,
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
} from "@material-ui/core";
import TimelineIcon from "@material-ui/icons/Timeline";
import {
  indicatorNames,
  indicatorTypes,
  indicatorCategs,
  indicatorMessages,
  categText,
  styles,
} from "./params";
import { mobileCheck } from "./utils";
import LineGraph from "./LineGraph";
import LineGraphWithFilter from "./LineGraphWithFilter";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#bcd794",
  },
});

export default function DataTool() {
  // load the valid indicator by county json
  const [validIndicators, setValidIndicators] = useState([]);
  useEffect(() => {
    fetch("json/indics_by_county.json", { cache: "no-store" })
      .then((res) => res.json())
      .then((data) => {
        setValidIndicators(data);
      });
  }, []);

  // populate the indicator select based on county
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [countyIndicators, setCountyIndicators] = useState([]);
  const handleCountySelect = (event, values) => {
    if (values) {
      // county was switched, reset indicator selector
      if (selectedCounty !== values.county) {
        setSelectedIndicator(null);
        setCountyIndicators([]);
      }
      setSelectedCounty(values.county);
      const indicatorsWithName = values.indicators.map((x) => ({
        name: indicatorNames[x],
        id: x,
      }));
      // split milestones out, sort, then recombine with them at the bottom
      const milestones = indicatorsWithName.map(
        (x) => x.name.includes("Milestones") && x
      );
      const nonMilestones = indicatorsWithName.map(
        (x) => !x.name.includes("Milestones") && x
      );
      const milestonesSorted = milestones
        .filter(Boolean)
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      const nonMilestonesSorted = nonMilestones
        .filter(Boolean)
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      const indicatorsSorted = nonMilestonesSorted.concat(milestonesSorted);
      setCountyIndicators(indicatorsSorted);
    } else {
      // county was cleared, reset values
      setSelectedCounty(null);
      setSelectedIndicator(null);
      setCountyIndicators([]);
    }
  };

  const [chartContext, setChartContext] = useState(null);
  const getChartContext = (indic) => {
    // use all context for the education indicators
    let contextArray;
    if (indicatorCategs[indic] === "children-succeeding") {
      // use spread operator to ensure new array creation for randomizing
      contextArray = [...categText["children-succeeding"]];
    }
    // else filter context by indicator
    else {
      // check to see if specific message exists for indicator
      if (indic in indicatorMessages) {
        // use spread operator to ensure new array creation for randomizing
        contextArray = [...indicatorMessages[indic]];
      } else {
        console.log(indic + " not in indicatorMessages");
        setChartContext(null);
        return;
      }
    }
    // if same indicator is selected, show different message if exists
    if (chartContext && contextArray.length > 1) {
      const index = contextArray.indexOf(chartContext);
      if (index > -1) {
        contextArray.splice(index, 1);
      }
    }
    const newContext =
      contextArray[Math.floor(Math.random() * contextArray.length)];
    setChartContext(newContext);
  };

  // get race columns then map style from params
  const [chartStyle, setChartStyle] = useState(null);
  const getChartStyle = (chartData) => {
    const groups = chartData["cols"]
      .map((column) => column.id !== "year" && column.id)
      .filter((x) => x);
    const style = groups.map((race) => styles[race]);
    setChartStyle(style);
  };

  // load the data for the graph
  const [chartData, setChartData] = useState(null);
  const [chartCounty, setChartCounty] = useState(null);
  const [chartIndic, setChartIndic] = useState(null);
  const [chartDataType, setChartDataType] = useState(null);
  const fetchChartData = () => {
    setChartData(null);
    const data = { county: selectedCounty, indic: selectedIndicator };
    fetch("/data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setChartCounty(selectedCounty);
        setChartIndic(selectedIndicatorName);
        setChartDataType(indicatorTypes[selectedIndicator]);
        getChartContext(selectedIndicator);
        getChartStyle(data);
        setChartData(data);
      });
  };

  // handle indicator selection
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [selectedIndicatorName, setSelectedIndicatorName] = useState(null);
  const handleIndicatorSelect = (event, values) => {
    if (values) {
      setSelectedIndicator(values.id);
      setSelectedIndicatorName(values.name);
    } else {
      setSelectedIndicator(null);
      setSelectedIndicatorName(null);
    }
  };

  // check mobile device
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    const mobile = mobileCheck();
    setMobile(mobile);
  }, []);

  // activate classes
  const classes = useStyles();

  return (
    <div>
      <Box my={1} display="flex" flexWrap="wrap" alignItems="center">
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="center"
          flexGrow={2}
          maxWidth={800}
        >
          <Box px={1} flexGrow={1} my={1} minWidth={250} maxWidth={500}>
            <Autocomplete
              id="county-select"
              options={validIndicators}
              getOptionLabel={(option) => option.county}
              fullWidth={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a County"
                  variant="outlined"
                />
              )}
              onChange={handleCountySelect}
            />
          </Box>
          <Box px={1} flexGrow={4} my={1} minWidth={250} maxWidth={600}>
            <Autocomplete
              key={selectedCounty}
              disabled={!selectedCounty}
              id="indic-select"
              options={countyIndicators}
              getOptionLabel={(option) => option.name}
              fullWidth={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select an Indicator"
                  variant="outlined"
                />
              )}
              onChange={handleIndicatorSelect}
            />
          </Box>
        </Box>
        <Box px={1} my={1} flexGrow={1} justifyContent="flex-start">
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disabled={!selectedIndicator || !selectedCounty}
            onClick={fetchChartData}
            startIcon={<TimelineIcon />}
          >
            Graph
          </Button>
        </Box>
      </Box>
      {chartData && (
        <Paper elevation={3} style={{ padding: 10, marginBottom: 10 }}>
          <Grid container alignItems={"center"} alignContent={"center"}>
            <Grid item lg={9} md={8} sm={12} xs={12}>
              {chartData.rows.length > 5 && !isMobile ? (
                <LineGraphWithFilter
                  chartData={chartData}
                  chartCounty={chartCounty}
                  chartIndic={chartIndic}
                  chartDataType={chartDataType}
                  chartStyle={chartStyle}
                />
              ) : (
                <LineGraph
                  chartData={chartData}
                  chartCounty={chartCounty}
                  chartIndic={chartIndic}
                  chartDataType={chartDataType}
                  chartStyle={chartStyle}
                  isMobile={isMobile}
                />
              )}
            </Grid>
            <Grid item lg={3} md={4} sm={12} xs={12}>
              <Card
                elevation={0}
                //variant="outlined"
                //classes={{ root: classes.root }}
              >
                <CardContent>
                  <Typography
                    paragraph={true}
                    variant={"body2"}
                    align="justify"
                  >
                    {chartContext["text"]}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="medium"
                    color="secondary"
                    target="_blank"
                    href={chartContext["source"]}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
