import React, { useEffect, useRef } from "react";
import { debounce } from "./utils";

export default function LineGraph(props) {
  const dashboardContainer = useRef(null);
  const chartContainer = useRef(null);
  const filterContainer = useRef(null);

  const chartWidth = "85%";
  const chartOptions = {
    vAxis: {
      minorGridlines: {
        count: 0,
        color: "transparent",
      },
      gridlines: {
        count: -1,
      },
    },
    hAxis: {
      format: "####",
      minorGridlines: {
        count: 0,
        color: "transparent",
      },
      gridlines: {
        count: props.chartData["rows"].length,
      },
    },

    title: props.chartCounty + ", " + props.chartIndic,
    legend: {
      position: "top",
      maxLines: 3,
    },
    chartArea: {
      width: chartWidth,
      height: "75%",
      top: "15%",
    },
    series: props.chartStyle,
    lineWidth: 2,
  };

  // handle data with more than 10 years to avoid cutting off labels
  let rangeGridCount;
  if (props.chartData["rows"].length > 10) {
    rangeGridCount = -1;
  } else {
    rangeGridCount = props.chartData["rows"].length;
  }
  const rangeOptions = {
    filterColumnIndex: 0,
    ui: {
      minRangeSize: 4,
      chartOptions: {
        chartArea: {
          width: chartWidth,
          height: "60%",
        },
        hAxis: {
          format: "####",
          textPosition: "out",
          gridlines: {
            count: rangeGridCount,
          },
        },
        fontSize: 12,
        series: props.chartStyle,
        lineWidth: 1.5,
      },
    },
  };

  useEffect(() => {
    const data = new window.google.visualization.DataTable(props.chartData);
    // if formatting year as number, make sure comma isnt displayed
    const date_formatter = new window.google.visualization.NumberFormat({
      pattern: "####",
    });
    date_formatter.format(data, 0);
    const dashboard = new window.google.visualization.Dashboard(
      dashboardContainer.current
    );
    //format y axis according to data type
    let axisFormat, axisTitle;
    if (props.chartDataType === "Percent") {
      axisFormat = "#'%'";
      axisTitle = null;
    } else {
      axisTitle = props.chartDataType;
      axisFormat = null;
    }
    chartOptions["vAxis"]["title"] = axisTitle;
    chartOptions["vAxis"]["format"] = axisFormat;
    //add range control
    const rangeSlider = new window.google.visualization.ControlWrapper({
      controlType: "ChartRangeFilter",
      containerId: filterContainer.current,
      options: rangeOptions,
    });
    //add chart
    const chart = new window.google.visualization.ChartWrapper({
      chartType: "LineChart",
      containerId: chartContainer.current,
      options: chartOptions,
    });
    //listen for changes to the range slider and adjust range accordingly
    window.google.visualization.events.addListener(
      rangeSlider,
      "statechange",
      function (e) {
        const range = rangeSlider.getState().range;
        //get the ceiling of the range difference and use that as number of gridlines
        const diff = Math.ceil(range.end - range.start);
        chart.setOption("hAxis.gridlines.count", diff);
      }
    );
    dashboard.bind(rangeSlider, chart);
    dashboard.draw(data);

    //https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
    const debouncedHandleResize = debounce(function handleResize() {
      dashboard.draw(data);
    }, 200);
    window.addEventListener("resize", debouncedHandleResize);
    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  return (
    <div style={{ height: "400px" }} ref={dashboardContainer}>
      <div style={{ height: "85%" }} ref={chartContainer} />
      <div style={{ height: "15%" }} ref={filterContainer} />
    </div>
  );
}
